@use '@angular/material' as mat;
@import "node_modules/@ng-select/ng-select/themes/material.theme.css";
@import 'node_modules/swiper/swiper-bundle.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.mirror-x{
 transform: scale(1,1);
}
.neg-mirror-x{
  transform: scale(-1,1);
}
.d-none{
  display: none;
}

.mat-datepicker-content{
  background-color: white;
}
.dialog-full{
  .mat-mdc-dialog-container .mdc-dialog__surface{
    background-color: transparent;
  }
}

.mat-mdc-snack-bar-container{
  &.error-snack{
    --mdc-snackbar-container-color: rgba(255, 108, 108, 0.76);
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled){
      color: white !important;
    }
  }
  &.success-snack{
    --mdc-snackbar-container-color: rgba(23, 147, 23, 0.72);
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled){
      color: white !important;
    }
  }
  &.info-snack{
    --mdc-snackbar-container-color: rgba(75, 159, 248, 0.72);
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled){
      color: white !important;
    }
  }
}

.only-mobile{
  @media (max-width: 425px){
    display: block !important;
  }
  @media (min-width: 426px){
    display: none !important;;
  }
}

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-3);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Noto Sans', sans-serif; }
